.CardsBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 36px;
  padding: 40px 0;
}

@media (max-width: 1023px) {
  .CardsBlock {
    gap: 30px;
    padding: 30px 0;
  }
}

@media (max-width: 767px) {
  .CardsBlock {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 20px 0;
  }
}

.Header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  padding: 24px 36px;
  z-index: 10;
}

.HeaderInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1600px;
}

.Logo {
  width: 60px;
  height: 60px;
  transition: transform 0.3s;
}

.Logo:hover {
  transform: scale(1.17);
}

.Logo > svg {
  width: 100%;
  height: auto;
}

@media (max-width: 1023px) {
  .Header {
    padding: 24px 24px;
  }
}

@media (max-width: 575px) {
  .Logo {
    width: 38px;
    height: 38px;
  }

  .Header {
    padding: 16px 16px;
  }
}

.PolicyWrap {
  padding: 164px 16px 80px 16px;
  gap: 40px;
  max-width: 720px;
  margin: 0 auto;
}

.Bold {
  font-weight: 700;
}

.Header,
.Section,
.PolicyWrap,
.Table {
  display: flex;
  flex-direction: column;
}

.Header {
  gap: 32px;
}

.Section {
  gap: 24px;
}

.Link {
  border-bottom: 1px solid #000;
}

.Table {
  gap: 16px;
  font-size: 14px;
}

.InnerList > ul {
  margin-top: 10px;
}

.Section ul {
  padding-left: 0;
  list-style: none;
}

.Section li {
  padding-left: 10px;
}

.Section ul li {
  padding-left: 38px;
  position: relative;
}

.Section ul li::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 2px;
  position: absolute;
  top: calc(50% - 5px);
  left: 0;
}

@media (max-width: 575px) {
  .PolicyWrap {
    padding-top: 149px;
  }
}

@media (max-width: 575px) {
  .PolicyWrap {
    padding-top: 111px;
  }
}

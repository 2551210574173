*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: Manrope, sans-serif;
  line-height: 1.6;
  cursor: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

p {
  font-weight: 400;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: none;
}

button {
  border: none;
  background-color: transparent;
  cursor: none;
  outline: none;
  padding: 0;
}

ol {
  margin: 0;
}

@media (max-width: 1023px) {
  html {
    cursor: default;
  }

  a {
    cursor: pointer;
  }
  
  button {
    cursor: pointer;
  }
}

.Footer {
  padding: 0 36px 40px;
  width: 100%;
  max-width: 1672px;
  margin: 0 auto;
}

.FooterMain {
  padding: 40px 0 0;
  border-top: 1px solid #000;
  display: grid;
  grid-template-columns: 1fr 515px;
  width: 100%;
}

.LinksSide {
  display: flex;
  gap: 117px;
}

.LinksBlock {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.Copyrights {
  transform: translate(0px, -30px);
  width: fit-content;
}

.LinkWithIcon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.LinksBlock > a {
  width: fit-content;
}

@media (max-width: 1023px) {
  .Footer {
    padding: 0 24px 40px;
  }
}

@media (max-width: 767px) {
  .Footer {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .FooterMain {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .Copyrights {
    transform: translate(0);
  }
}

@media (max-width: 575px) {
  .Footer {
    padding: 0 16px 30px;
  }

  .FooterMain {
    padding: 30px 0 0;
  }

  .LinksSide {
    flex-direction: column;
    gap: 24px;
  }
}

@keyframes RollText {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -webkit-transform: translateY(-32px);
    -ms-transform: translateY(-32px);
    transform: translateY(-32px);
  }
  10% {
    opacity: 0;
    -webkit-transform: translateY(-32px);
    -ms-transform: translateY(-32px);
    transform: translateY(-32px);
  }
  12% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  27% {
    opacity: 0;
    -webkit-transform: translateY(32px);
    -ms-transform: translateY(32px);
    transform: translateY(32px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.Roll {
  padding-top: 50px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
}

.RollText {
  animation: RollText 25s linear infinite 0s;
  position: absolute;
  opacity: 0;
  margin: 0 36px;
  text-align: center;
}

.RollText:nth-child(1) {
  animation-delay: -3s;
}

.RollText:nth-child(2) {
  animation-delay: 2s;
}

.RollText:nth-child(3) {
  animation-delay: 7s;
}

.RollText:nth-child(4) {
  animation-delay: 12s;
}

.RollText:nth-child(5) {
  animation-delay: 17s;
}

@media (max-width: 1023px) {
  .Roll {
    padding-top: 40px;
  }
  .RollText {
    margin: 0 24px;
  }
}

@media (max-width: 767px) {
  .Roll {
    padding-top: 30px;
  }
}

@media (max-width: 575px) {
  .RollText {
    margin: 0 16px;
  }
}

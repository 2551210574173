.Circle {
  width: 96px;
  height: 96px;
  position: fixed;
  top: -48px;
  left: -48px;
  z-index: 1000;
  background: #000;
  border-radius: 50%;
  transform: scale(0.16, 0.16);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
}

.InnerText {
  color: #fff;
  font-size: 20px;
  transform: scale(0, 0);
}

@media (max-width: 1023px) {
  .Circle {
    display: none;
  }
}

.h1,
.h2,
.h3,
.h4,
.h6 {
  font-weight: 600;
}

.h1 {
  font-size: 100px;
  line-height: 1.5;
}

.h2 {
  font-size: 80px;
  line-height: 1.3;
}

.h3 {
  font-size: 57px;
}

.h4 {
  font-size: 36px;
}

.h5 {
  font-size: 24px;
  font-weight: 700;
}

.h6 {
  font-size: 22px;
}

.lg {
  font-size: 32px;
  line-height: 1.6;
}

.md {
  font-size: 20px;
}

.sm {
  font-size: 16px;
}

.bold {
  font-weight: 600;
}

.low {
  line-height: 1.4;
}

@media (max-width: 1023px) {
  .h1 {
    font-size: 80px;
  }

  .h5 {
    font-size: 18px;
  }

  .lg {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .h1 {
    font-size: 60px;
  }

  .h3 {
    font-size: 24px;
  }

  .h5 {
    font-size: 15px;
  }

  .sm {
    font-size: 13px;
  }

  .lg {
    font-size: 24px;
  }
}

@media (max-width: 575px) {
  .h1 {
    font-size: 36px;
  }

  .md {
    font-size: 16px;
  }

  .lg {
    font-size: 20px;
  }
}

.Main {
  margin: 0 auto;
  max-width: 578px;
  padding: 0 16px;
  width: 100%;
}

.Main > form {
  width: 100%;
}

.Form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.InputsBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 45px 28px;
  padding: 42px 0 46px 0;
  width: 100%;
}

.Input {
  outline: none;
  background: 0 0;
  border: none;
  border-bottom: 1px solid #8a8a8a;
  padding-bottom: 16.5px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  transition: 0.2s border ease;
  cursor: none;
}

.Input::placeholder {
  color: #fff;
}

.Input:focus {
  border-bottom: 1px solid #fff;
}

.InputError {
  border-bottom: 1px solid #a10000;
}

.Input:last-child {
  grid-column: span 2;
}

.Label {
  display: flex;
  align-items: center;
  cursor: none;
}

.Label > input {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

.Agreement {
  font-size: 14px;
}

.CheckBox {
  width: 17px;
  height: 17px;
  border: 2px solid #8a8a8a;
  border-radius: 4px;
  flex-shrink: 0;
  position: relative;
  margin-right: 14px;
  transition: 0.3s border;
}

.AgreementError + .CheckBox {
  border: 2px solid #a10000;
}

.Label > input + .CheckBox::before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 2px;
  background-color: transparent;
  position: absolute;
  left: calc(50% - 4.5px);
  top: calc(50% - 4.5px);
  transition: background-color 0.2s ease;
}

.Label > input:focus + .CheckBox {
  border: 2px solid #fff;
}

.Label:hover > input + .CheckBox {
  border: 2px solid #fff;
}

.Label > input:checked + .CheckBox {
  border: 2px solid #fff;
}

.Label > input:checked + .CheckBox::before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 2px;
  background-color: #fff;
  position: absolute;
  left: calc(50% - 4.5px);
  top: calc(50% - 4.5px);
}

.Link {
  text-decoration: underline;
}

.Button {
  margin: 0 auto;
  margin-top: 66px;
  background-color: #fff;
  height: 40px;
  align-items: center;
  justify-content: center;
  width: 465px;
  color: #171717;
  font-size: 14px;
  font-weight: 600;
  transition: 0.2s opacity ease;
}

.Button:hover {
  opacity: 0.8;
}

.Input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff;
}

.Textarea {
  width: 100%;
  resize: none;
}

@media (max-width: 1023px) {
  .Main {
    max-width: 502px;
  }
  .InputsBlock {
    gap: 45px 15px;
  }
  .Button {
    width: 100%;
  }

  .Input {
    cursor: text;
  }

  .Label {
    cursor: default;
  }

  .CheckBox {
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .Main {
    width: 100%;
    max-width: 100%;
  }

  .InputsBlock {
    gap: 30px 15px;
    grid-template-columns: 1fr;
    padding: 30px 0 40px 0;
  }

  .Input:last-child {
    grid-column: span 1;
  }

  .Button {
    margin-top: 44px;
  }

  .Input,
  .Agreement {
    font-size: 13px;
  }
}

.Main {
  width: 100%;
  padding: 0 36px;
  position: relative;
}

.Decorations {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000px;
  z-index: 0;
  overflow: hidden;
}

.Decorations div {
  position: absolute;
  border-radius: 50%;
  width: 500px;
  height: 500px;
  transform: translate3d(0, 0, 0);
  filter: blur(100px);
}

.Decorations div:first-child {
  top: 90px;
  left: -70px;
  background: #e394d8;
}

.Decorations div:nth-child(2) {
  top: 320px;
  right: -50px;
  background: #908ce8;
}

.Decorations div:nth-child(3) {
  top: -50px;
  right: 300px;
  background: #94e5df;
}

.Decorations div:nth-child(4) {
  right: 550px;
  top: 260px;
  background: #b5ecd0;
}

.Content {
  z-index: 2;
  position: relative;
  margin: 0 auto;
  max-width: 1600px;
}

@media (max-width: 1023px) {
  .Main {
    padding: 0 24px;
  }
}

@media (max-width: 575px) {
  .Main {
    padding: 0 16px;
  }
}

.ContactForm {
  background-color: #171717;
  color: #fff;
  min-height: 1024px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 203px;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 36px;
  max-width: 1672px;
  margin: 0 auto;
}

.Logo {
  width: 60px;
  height: 60px;
  transition: transform 0.3s ease;
}

.Logo:hover {
  transform: scale(1.17);
}

.Logo > svg {
  width: 100%;
  height: auto;
}

.Close {
  width: 50px;
  height: 50px;
}

.Close > svg {
  width: 100%;
  height: auto;
}

.Close {
  transition: opacity 0.2s ease;
}

.Close:hover {
  opacity: 0.8;
}

@media (max-width: 1023px) {
  .Header {
    padding: 24px 24px 0;
  }
}

@media (max-width: 767px) {
  .ContactForm {
    min-height: 800px;
    padding-bottom: 48px;
    gap: 42px;
  }

  .Header {
    padding: 16px 16px 22px;
  }

  .Logo {
    width: 38px;
    height: 38px;
  }

  .Close {
    width: 25px;
    height: 25px;
  }
}

.Card {
  display: flex;
  flex-direction: column;
  gap: 14px;
  transition: transform 0.5s ease;
}

.Text {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.Title {
  font-weight: 500;
}

.Description {
  opacity: 0.4;
}

.Img {
  width: 100%;
}

.Active:hover {
  transform: scale(0.95);
}

@media (max-width: 575px) {
  .Card {
    gap: 8px;
  }

  .Text {
    gap: 2px;
  }
}

.TopBlock {
  padding-top: 164px;
  padding-bottom: 40px;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ButtonWrap {
  display: flex;
  justify-content: flex-end;
}

.ButtonWrap > button {
  display: flex;
  gap: 12px;
  align-items: center;
}

@media (max-width: 1023px) {
  .TopBlock {
    gap: 40px;
    padding-top: 144px;
  }

  .ButtonWrap {
    justify-content: flex-start;
  }
}

@media (max-width: 575px) {
  .TopBlock {
    gap: 30px;
    padding-top: 104px;
    padding-bottom: 30px;
  }

  .ButtonWrap svg {
    width: 24px;
    height: 24px;
  }
}

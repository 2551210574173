@-webkit-keyframes flipover {
  0%,
  to {
    transform: translateY(0);
    opacity: 1;
  }

  49% {
    transform: translateY(0.6em);
    opacity: 0;
  }

  50% {
    transform: translateY(-0.6em);
    opacity: 0;
  }
}

@keyframes flipover {
  0%,
  to {
    transform: translateY(0);
    opacity: 1;
  }

  49% {
    transform: translateY(0.6em);
    opacity: 0;
  }

  50% {
    transform: translateY(-0.6em);
    opacity: 0;
  }
}

.link {
  position: relative;
}

.link::after,
.link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #000;
  opacity: 0;
}

.link::before {
  transform-origin: right;
  transform: scaleX(1);
  transition:
    transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.16s,
    opacity 0.1s linear 0.72s;
}

.link::after {
  transform-origin: left;
  transform: scaleX(0);
  transition:
    transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
    opacity 0s linear 0.6s;
}

.link:hover:before {
  transform: scaleX(0);
  transition:
    transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
    opacity 0s;
  opacity: 1;
}

.link:hover:after {
  transform: scaleX(1);
  transition:
    transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.16s,
    opacity 0s;
  opacity: 1;
}

.btn {
  padding: 8px 30px;
  background-color: #000;
  color: #fff;
  border: 2px solid #000;
  border-radius: 100px;
  width: fit-content;
  transition:
    background-color 0.2s ease-out,
    color 0.1s linear 0.05s;
}

.btn > p {
  font-weight: 600;
}

.btn path {
  transition: fill 0.2s ease-out;
}

.btn:hover {
  color: #000;
  background-color: #fff;
}

.btn:hover path {
  fill: #000;
}

.btn:hover > p {
  -webkit-animation: flipover 0.5s cubic-bezier(0.3, 0.86, 0.36, 0.95);
  animation: flipover 0.5s cubic-bezier(0.3, 0.86, 0.36, 0.95);
}

.btn:hover path {
  -webkit-animation: flipover 0.5s cubic-bezier(0.3, 0.86, 0.36, 0.95);
  animation: flipover 0.5s cubic-bezier(0.3, 0.86, 0.36, 0.95);
}

.gray {
  color: rgba(0, 0, 0, 0.3);
}

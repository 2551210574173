@font-face {
  font-family: 'Manrope';
  src: url('./Manrope/Manrope-Regular.woff') format('woff2'),
    url('./Manrope/Manrope-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./Manrope/Manrope-Medium.woff2') format('woff2'),
  url('./Manrope/Manrope-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./Manrope/Manrope-SemiBold.woff2') format('woff2'),
    url('./Manrope/Manrope-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.Cookie {
  position: fixed;
  z-index: 85;
  bottom: 20px;
  right: 50%;
  transform: translateX(50%);
  background-color: #f7f7f7;
  width: 100%;
  max-width: 450px;
  padding: 20px;
  border: 1px solid #000000;
  display: flex;
  align-items: center;
  gap: 20px;
}

.Button {
  background-color: #111111;
  color: #ffffff;
  font-size: 15px;
  padding: 6px 16px;
}

.Link {
  border-bottom: 1px solid #171717;
}

@media (max-width: 575px) {
  .Cookie {
    right: 0;
    left: 0;
    transform: initial;
    width: auto;
    max-width: 100%;
    flex-direction: column;
    padding: 12px 16px;
    bottom: 0;
  }

  .Button {
    width: 100%;
  }
}

.Main {
  margin: 0 auto;
  max-width: 913px;
  padding: 0 16px;
}

.HeadLine {
  padding-bottom: 38px;
  border-bottom: 1px solid #444;
}

.TextWrap {
  padding: 40px 0 55px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.Rebase {
  width: 102px;
}

.Rebase > svg {
  width: 100%;
  height: auto;
}

.Rebase path {
  fill: #fff;
}

.Button {
  margin: 0 auto;
  background-color: #fff;
  height: 40px;
  align-items: center;
  justify-content: center;
  width: 465px;
  color: #171717;
  font-size: 14px;
  font-weight: 600;
  transition: 0.2s opacity ease;
}

.Button:hover {
  opacity: 0.8;
}

@media (max-width: 1023px) {
  .Main {
    max-width: 502px;
  }

  .Text {
    font-size: 15px;
  }

  .Button {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .Main {
    width: 100%;
    max-width: 100%;
    padding-bottom: 368px;
  }

  .HeadLine {
    padding-bottom: 38px;
  }

  .TextWrap {
    padding: 26px 0 45px;
    gap: 43px;
  }

  .Rebase {
    width: 72px;
  }
}
